import { Page, Document, StyleSheet, Text, View } from "@react-pdf/renderer";

import './style/Visualize.scss'

// Pseudo / pronom / perso / univers / depart musique / matos presta

const VisZak = ({ data }: any) => (
  <table className="visualize">
    <thead className="visualize_title_row">
      <th className="visualize_title_row_text">
        Pseudo
      </th>
      <th className="visualize_title_row_text">
        Pronom
      </th>
      <th className="visualize_title_row_text">
        Personnage
      </th>
      <th className="visualize_title_row_text">
        Univers
      </th>
      <th className="visualize_title_row_text">
        Départ musique
      </th>
      <th className="visualize_title_row_text">
        Matériel presta
      </th>
    </thead>
    <br />
    {data.map((row: any) => (
      <tbody className="visualize_content_row">
        <td className="visualize_content_row_text">
          {row[3]}
        </td>
        <td className="visualize_content_row_text">
          {row[5]}
        </td>
        <td className="visualize_content_row_text">
          {row[9]}
        </td>
        <td className="visualize_content_row_text">
          {row[10]}
        </td>
        <td className="visualize_content_row_text">
          ______
        </td>
        <td className="visualize_content_row_text">
          ______
        </td>
      </tbody>
    ))}
    <br />
  </table>
  // <Document>
  //   <Page size="A5">
  //     <View style={styles.tableau}>
  //       <View style={styles.ligne}>
  //         <View style={styles.cellule}>
  //           <Text style={styles.bold}>Pseudo</Text>
  //         </View>
  //         <View style={styles.cellule}>
  //           <Text style={styles.bold}>Pronom</Text>
  //         </View>
  //         <View style={styles.cellule}>
  //           <Text style={styles.bold}>Personnage</Text>
  //         </View>
  //         <View style={styles.cellule}>
  //           <Text style={styles.bold}>univers</Text>
  //         </View>
  //         <View style={styles.cellule}>
  //           <Text style={styles.bold}>Départ musique</Text>
  //         </View>
  //         <View style={styles.cellule}>
  //           <Text style={styles.bold}>Matériel presta</Text>
  //         </View>
  //       </View>
  //       <hr />

  //       {data.map((row: any, index: any) => (
  //         <View key={index} style={styles.ligne}>
  //           <View style={styles.cellule}>
  //             <Text>{row[3]}</Text>
  //           </View>
  //           <View style={styles.cellule}>
  //             <Text>{row[5]}</Text>
  //           </View>
  //           <View style={styles.cellule}>
  //             <Text>{row[9]}</Text>
  //           </View>
  //           <View style={styles.cellule}>
  //             <Text>{row[10]}</Text>
  //           </View>
  //           <View style={styles.cellule}>
  //             <Text>______</Text>
  //           </View>
  //           <View style={styles.cellule}>
  //             <Text>______</Text>
  //           </View>
  //           <hr />
  //         </View>
  //       ))}
  //     </View>
  //   </Page>
  // </Document>
);

export default VisZak;

/**
 * 1. Select contest
 *  - Classic
 *  - Medieval
 * 
 * 2. Read the notation pdf file
 * 
 * 3. Write on the document
 * 
 * 4. Render 2 new PDFs to download
 *  - adult
 *  - child
 */


import React, { useState } from "react";
import { pdfjs } from 'react-pdf';
import { Page, Document, StyleSheet, Text, View, Image, PDFDownloadLink } from "@react-pdf/renderer";

import { PDFDocument, rgb } from 'pdf-lib';

import * as XLSX from "xlsx";

import nota from "../assets/img/nota.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff", // Couleur de fond de la page
  },
  imageContainer: {
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  textContainer: {
    position: "absolute",
    top: "50%", // Position verticale du texte
    left: "50%", // Position horizontale du texte
    transform: "translate(-50%, -50%)", // Centrer le texte
  },
  texte: {
    fontSize: 16,
    color: "#000000", // Couleur du texte
  },
});

let loadingReportData = true;
function Nota() {
  const [data, setData] = useState<any[]>([]);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        console.log(excelData);
        setData(excelData);
        loadingReportData = false;
      };

      reader.readAsBinaryString(file);
      console.log(loadingReportData);
    }
  };

  return (
    <div>
      <h1>Fiche de notation</h1>
      <hr />
      <input type="file" accept=".xlsx" onChange={handleFileUpload} />
      <hr />

      <div>
        {!loadingReportData && (
          <PDFDownloadLink
            document={<PredDoc data={data} />}
            fileName="predjuging.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        )}
        <br />
        {/* {data.length > 0 ? <PredDoc data={data} /> : <div>No data</div>} */}
        <hr />
      </div>
    </div>
  );
}

const PredDoc = ({ data }: any) => (
  <Document>
      <Page size="A4">
        <View style={styles.page}>
          {/* Image de fond */}
          <Image src={nota} style={styles.imageContainer} />

          {/* Texte positionné en position absolue */}
          <View style={styles.textContainer}>
            <Text style={styles.texte}>Votre texte ici</Text>
          </View>
        </View>
      </Page>
    </Document>
);

export default Nota;

import { Page, Document, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  bold: {
    fontStyle: "normal",
    fontWeight: "bold",
  },

  tableau: {
    width: "100%",
    fontSize: 8,
  },
  ligne: {
    flexDirection: "row",
  },
  cellule: {
    flex: 1,
    padding: 8,
  },
});

// Pseudo / pronom / perso / univers / depart musique / matos presta

const Zak = ({ data }: any) => (
  <Document>
    <Page size="A5">
      <View style={styles.tableau}>
        <View style={styles.ligne}>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Pseudo</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Pronom</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Personnage</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>univers</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Départ musique</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Matériel presta</Text>
          </View>
        </View>
        <hr />

        {data.map((row: any, index: any) => (
          <View key={index} style={styles.ligne}>
            <View style={styles.cellule}>
              <Text>{row[3]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>{row[5]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>{row[9]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>{row[10]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>______</Text>
            </View>
            <View style={styles.cellule}>
              <Text>______</Text>
            </View>
            <hr />
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default Zak;

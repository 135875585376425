import React from "react";
import { Link, Outlet } from "react-router-dom";

function Home() {
  return (
    <div>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/pred">Pred</Link>
          </li>
          <li>
            <Link to="/nota">Nota</Link>
          </li>
          <li>
            <Link to="/pass">Pass</Link>
          </li>
        </ul>
      </nav>
      <hr />

      <Outlet />
    </div>
  );
}

export default Home;

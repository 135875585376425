import React from "react";
import {
  Routes,
  Route,
  Outlet,
  Link,
  Router,
  BrowserRouter,
} from "react-router-dom";

import "./App.css";

import Home from "./Home";
import Pred from "./methods/Pred";
import Nota from "./methods/Nota";
import Pass from "./methods/Pass";
// import Homepage from "./pages/components/home/Homepage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="pred" element={<Pred />} />
            <Route path="nota" element={<Nota />} />
            <Route path="pass" element={<Pass />} />

            {/* <Route path="homepage" element={<Homepage />} /> */}

            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>

      <h2>v1.2.0</h2>
    </div>
  );
}

export default App;

import { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as XLSX from "xlsx";

import DocQuentin from './Pass/Document/DocQuentin'
import VisQuentin from './Pass/Visualize/VisQuentin'

import DocZak from './Pass/Document/DocZak'
import VisZak from './Pass/Visualize/VisZak'

import DocAntoine from './Pass/Document/DocAntoine'
import VisAntoine from './Pass/Visualize/VisAntoine'


let loadingReportData = true;
export default function Pass() {
  const [data, setData] = useState<any[]>([]);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        console.log(excelData);

        setData(cleanData(excelData));
        loadingReportData = false;
      };

      reader.readAsBinaryString(file);
    }
  };

  const cleanData = (data: any) => {
    let newTab: any = [];
    for (let i = 0; i < data.length; i++) {
      let element = data[i]
      if (i > 0 && i % 2 === 0) {
        newTab.push(element)
      }
    }

    return newTab
  }

  return (
    <div>
      <h1>Ordre de passage</h1>
      <hr />
      <input type="file" accept=".xlsx" onChange={handleFileUpload} />
      <hr />

      <div>
        <h1>Quentin</h1>
        {!loadingReportData && (
          <PDFDownloadLink
            document={<DocQuentin data={data} />}
            fileName="passage_quentin.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        )}
        <br />
        {data.length > 0 ? <VisQuentin data={data} /> : <div>No data</div>}
        <hr />
      </div>

      <div>
        <h1>Zak</h1>
        {!loadingReportData && (
          <PDFDownloadLink
            document={<DocZak data={data} />}
            fileName="passage_zak.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        )}
        <br />
        {data.length > 0 ? <VisZak data={data} /> : <div>No data</div>}
        <hr />
      </div>

      <div>
        <h1>Antoine</h1>
        {!loadingReportData && (
          <PDFDownloadLink
            document={<DocAntoine data={data} />}
            fileName="passage_antoine.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        )}
        <br />
        {data.length > 0 ? <VisAntoine data={data} /> : <div>No data</div>}
        <hr />
      </div>
    </div>
  );
}

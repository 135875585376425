import { Page, Document, StyleSheet, Text, View } from "@react-pdf/renderer";

import './style/Visualize.scss'

const styles = StyleSheet.create({
  bold: {
    fontStyle: "normal",
    fontWeight: "bold",
  },

  tableau: {
    width: "100%",
    fontSize: 10,
  },
  ligne: {
    flexDirection: "row",
  },
  cellule: {
    flex: 1,
    padding: 8,
  },
});

// Pseudo / pronom / perso / univers / depart musique / matos presta

const VisQuentin = ({ data }: any) => (
  <table className="visualize">
    <thead className="visualize_title_row">
      <th className="visualize_title_row_text">
        Pseudo
      </th>
      <th className="visualize_title_row_text">
        Pronom
      </th>
      <th className="visualize_title_row_text">
        Personnage
      </th>
      <th className="visualize_title_row_text">
        Univers
      </th>
    </thead>
    <br />
    {data.map((row: any) => (
      <tbody className="visualize_content_row">
        <td className="visualize_content_row_text">
          {row[3]}
        </td>
        <td className="visualize_content_row_text">
          {row[5]}
        </td>
        <td className="visualize_content_row_text">
          {row[9]}
        </td>
        <td className="visualize_content_row_text">
          {row[10]}
        </td>
      </tbody>
    ))}
    <br />
  </table>
);

export default VisQuentin;

import { useState } from "react";
import { Page, Document, StyleSheet, Text, View } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as XLSX from "xlsx";

const styles = StyleSheet.create({
  bold: {
    fontStyle: "normal",
    fontWeight: "bold",
  },

  tableau: {
    width: "100%",
    fontSize: 8,
    textAlign: "center",
  },
  ligne: {
    flexDirection: "row",
  },
  cellule: {
    flex: 1,
    padding: 8,
  },
});

const badRow = "user_explanation";

let loadingReportData = true;
function Pred() {
  const [data, setData] = useState<any[]>([]);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        excelData.shift();
        excelData.shift();
        excelData.map((row: any) => {
          row[0] === badRow
            ? excelData.splice(excelData.indexOf(row), 1)
            : console.log(row[0]);
        });

        setData(excelData);
        loadingReportData = false;
      };

      reader.readAsBinaryString(file);
    }
  };

  return (
    <div>
      <h1>Predjuging</h1>
      <hr />
      <input type="file" accept=".xlsx" onChange={handleFileUpload} />
      <hr />

      <div>
        {!loadingReportData && (
          <PDFDownloadLink
            document={<PredDoc data={data} />}
            fileName="predjuging.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        )}
        <br />
        {data.length > 0 ? <PredDoc data={data} /> : <div>No data</div>}
        <hr />
      </div>
    </div>
  );
}

const PredDoc = ({ data }: any) => (
  <Document>
    <Page size="A4" orientation="landscape">
      <View style={styles.tableau}>
        <View style={styles.ligne}>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Présence</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Pseudo</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Pronom</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Téléphone</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Personnage</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Univers</Text>
          </View>
          <View style={styles.cellule}>
            <Text style={styles.bold}>Fin de passage</Text>
          </View>
          <hr />
        </View>

        {data.map((row: any, index: any) => (
          <View key={index} style={styles.ligne}>
            <View style={styles.cellule}>
              <Text>O</Text>
            </View>
            <View style={styles.cellule}>
              <Text>{row[3]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>{row[5]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>{row[7]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>{row[9]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>{row[10]}</Text>
            </View>
            <View style={styles.cellule}>
              <Text>O</Text>
            </View>
            <hr />
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default Pred;
